

.posts-body{
    padding: 10px 50px 10px 50px;
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.3rem;
}.post-img img{
    max-width: 100%;
    border-radius: 10px;
}
.post-img{
    margin: 20px 0 5px;
    
}.post{
    border-bottom: 1px solid #eff3f4;
}
.action-item img {
    width: 20px;
    cursor: pointer;
}
.post-actions {
    margin-bottom: 10px;
}
.action-val,.action-item img {
    opacity: 0.6;
    padding-right:2px ;
}
.comment-item{
    border-top: 1px solid #eff3f4;
}