.menu-list {
    padding: 10px 10px 10px 33px;
}
.logo{
    width: 100%;
    padding: 0 30px 10px 10px;
}
.logo img{
    width: 100%;
    max-width: 150px;
}
.menu-list {
    padding: 10px 26px 10px 24px;
    width: 100%;
}
.menu-item {
    font-size: 21px;
    margin: 35px 0;
    display: flex;
    cursor: pointer;
}
.menu-item:hover{
    font-weight: 500;
}
.menu-item-icon {
    padding-right: 10px;
    padding-top: 2px;
}

.menu-item-icon img {
    max-width: 20px;
    width: 25px;
}
 .post-btn-cont button{
    width: 80%;
    border-radius: 20px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    padding: 12px 10px;
    border: none;
    outline: none;
    cursor: pointer;
}
.modal-heading-close{cursor: pointer;}
.post-input{
    font-size: 20px;
    padding: 10px;
    height: 200px;
    width: 30vw;
    outline: 0;
    resize: none;
    border: 0;
}

.post-submit-btn{
    /* display: flex;
    justify-content: flex-end; */
}
.post-submit-btn button{
    width: 100px;
    border-radius: 20px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    padding: 12px 10px;
    border: none;
    outline: none;
    cursor: pointer;
}
.create-post-icon img{
    max-width: 20px;
    width: 20px;
}
.create-post-icon.selected{
    border: 1px solid black;
}
.url-input{
    margin: 5px 0;
}
.url-input input{
    width: 60%;
    padding: 10px 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #cecece;
    outline: 0;
}
.image-preview img {
    max-width: 100px;
    border-radius: 5px;
}
.menu-item a{
    text-decoration: none;
    color: black;
}