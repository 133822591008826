.post-author-icon {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    border: 1px solid grey;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.post-author-icon img{
    max-width: 36px;
}

.circle {
	line-height: 0;		/* remove line-height */ 
	display: inline-block;	/* circle wraps image */
	margin: 5px;
  border: 1px solid rgba(200,200,200,0.4);
	border-radius: 50%;	/* relative value */
	/*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
	transition: linear 0.25s;
  height: 40px;
  width: 40px;
}
.circle img {
	border-radius: 50%;	
    max-width: 40px;
}
.author-name{
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding-top: 6px;
    font-size: 18px;
    cursor: pointer;
}
.author-name:hover{
    text-decoration: underline;
}
.post-time{
    font-size: 13px;
    color: #5f5f5f;
}
.notime{
    padding-top: 0 !important;
}