body{
  height: auto;
  min-height: 100vh;
  overflow-y: scroll;
}

.d-flex{
  display: flex;
}
.flex-1{
  flex: 1;
}
.popup-overlay{
  background: rgba(0, 0, 0, 0.5);
}
.modal-content{
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;

}.flex-end{
  justify-content: flex-end;
}
.flex-j-e{
  justify-content: space-evenly;
}
.flex-a-c{
  align-items: center;
}
.flex-a-e{
  align-items: end;
}
.popup-content{
  max-width: 40vw;
}

@media only screen and (max-width: 767px) {
  .popup-content{
    max-width: 98vw !important;
  }
  .app-cont{
    padding: 0 !important;
    max-width: 100% !important;
  }
  .l-sidebar, .r-sidebar{
    display: none !important;
  }
  .middle-cont{
    width: 100% !important;
    max-width: 100% !important;
  }
  .posts-body{
    padding: 30px !important;
  }
  .logo-n img{
    max-width: 90px !important;
  }
  .slash{
    font-size: 28px !important;
    margin-right: 10px !important;
    margin-top: 0 !important;
  }
  .comm{
    font-size: 25px !important;
    margin-top: 3px !important;
  }
}