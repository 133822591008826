.content{
    padding: 5px 20px;
}
.replyto{
    padding: 5px 20px;
    color: grey;
}
.react-datetime-picker{
    width: 60%;
}

.react-datetime-picker__wrapper{
    border: 1px solid #cecece;
    border-radius: 5px;
    width: calc(100% + 22px);
    padding: 6px 10px;
}

.create-post-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
}
.addon-label{
    width: 60px;
    display: flex;
    color: #555555;
}