.middle-top{
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 10px 15px 0;
    /* border: 1px solid #eff3f4; */
}
.middle-hdr{
    
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    font-size: 16px;
    outline: 0;
    position: relative;
    z-index: 2;
    padding: 22px 20px 18px;
}
