.search {
    display: flex;
    width: 100%;
    background-color: #f1f1f1;
    padding: 10px 15px;
    border-radius: 26px;
}
.search img{
    max-width: 40px;
    opacity: 0.6;
}
.search input{
    border: 0;
    outline: 0;
    width: 100%;
    padding: 5px 10px;
    background-color: #f1f1f1;
    color: #4d4d4d;
    font-size: 16px;
}
.card-title{
    font-weight: 800;
    font-size: 24px;
}
.card {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 10px;
    width: 100%;
}
.card-title {
    font-weight: 800;
    font-size: 24px;
}
.card-body {
    margin: 18px 0 10px;
}
.trend-item {
    margin: 20px 0;
}
.trend-item-title {
    font-weight: 600;
    font-size: 18px;
    display: flex;
}
.trend-item-title img{
    width: 16px;
    height: 16px;
    padding-top: 5px;
    padding-right: 3px;

}
.trend-item-count {
    font-size: 13px;
    color: #585858;
    /* padding-left: 12px; */
    /* font-weight: 500; */
}
.trend-item button{
    cursor: pointer;
    border: 1px solid #767676;
    border-radius: 3px;
    background-color: #f1f1f1;
    outline: 0;
}
.trend-item button:hover{
    background-color: black;
    color: white;
    border-color:black ;
}
