
.user-cont {
    padding: 50px 10px 10px;
}
.user-circle {
	line-height: 0;		/* remove line-height */ 
	display: inline-block;	/* circle wraps image */
	margin: 5px;
  border: 1px solid rgba(200,200,200,0.4);
	border-radius: 50%;	/* relative value */
	/*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
	transition: linear 0.25s;
  height: 100px;
  width: 100px;
  margin-right: 20px;
}
.user-circle img {
	border-radius: 50%;	
    max-width: 100px;
}
.user-name{
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding-top: 6px;
    font-size: 26px;
}
.user-posts{
    font-size: 16px;
    color: #5f5f5f;
}
.user-info-card {
    padding-left: 130px;
    padding-right: 110px;
    margin: 10px 0;
}
.stat-item {
    font-weight: 700;
    margin: 10px 25px 10px 0;
}
.stat-item .key{
    font-size: 14px;
    color: #292929;
}
.stat-item .val{
    font-size: 20px;
}
.user-follow{
    padding-right: 50px;
}
.user-follow button{
    cursor: pointer;
    background-color: black;
    color: white;
    font-size: 17px;
    padding: 5px 16px;
    outline: 0;
    border: 0;
    border-radius: 9px;
    font-weight: 600;
}
.user-tabs{
    margin:30px 0 20px;
    border-top: 1px solid #eff3f4;
    border-bottom: 1px solid #eff3f4;
}
.tab-item{
    padding: 20px 10px;
    cursor: pointer;
}
.tab-item.active{
    font-weight: 700;
}
.user-follow-sec{
    padding-right: 50px;
}
.user-follow-sec button{
    cursor: pointer;
    background-color: black;
    color: white;
    font-size: 17px;
    padding: 5px 16px;
    outline: 0;
    border: 0;
    border-radius: 9px;
    font-weight: 600;
}