.app-cont{
    max-width: 70vw;
    margin: 0 auto;
    height: 100%;
}
.cont{
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}
.l-sidebar{
    display: flex;
    height: 100%;
    justify-content: flex-end;
    min-width: 285px;
    overflow: hidden;
    pointer-events: none;
}
.l-sidebar-inner,.r-sidebar-inner{
    pointer-events: auto;
    position: fixed;
    padding-top: 20px;
    min-width: 285px
}
.r-sidebar-inner{
    padding: 15px;
}
.r-sidebar{
    display: flex;
    height: 100%;
    /* justify-content: flex-end; */
    justify-content: flex-start;
    min-width: 285px;
    overflow: hidden;
    pointer-events: none;
}
.middle-cont{
    flex: 1;
    border: 1px solid #eff3f4;
    max-width: 40vw;
    
}
.middle-hdr {
    justify-content: space-evenly;
    border-bottom: 1px solid #eff3f4;
    padding-top: 10px;
}
.middle-hdr-menu-item{
    font-weight: 600;
    color: #747474;
    font-size: 16px;
}
.middle-hdr-menu-item.active{
    color: #2e2e2e;
}
.page-hdr{
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
}