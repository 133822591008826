.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Optional: Set height to 100% of the viewport height for full-page centering */
}
.logo-n {
  display: flex;
  align-items: center;
}
.slash {
  font-size: 100px;
  margin-top: -16px;
  margin-left: 10px;
  margin-right: 20px;
}
.comm {
  font-size: 90px;
}
.sign{
    font-size: 40px;
    text-decoration: underline;
}
.conde-sign-btn{
    margin: 0 auto;
    text-align: center;
}

.conde-sign-btn button{
    padding: 15px 40px;
    background-color: black;
    cursor: pointer;
    outline: 0;
    border: 0;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    font-weight: 800;
}